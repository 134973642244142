import React, { useContext, useEffect, useState } from "react";
import _css from "./TopBar.module.scss"
import { HeaderMenuIcon, StatsTopBarButtonIcon } from "assets";
import { Bullseye, Grid, GridItem, Split, SplitItem, Text, Title, Tooltip, Truncate } from "@patternfly/react-core";
import { SessionsContext, SideBarNavContext } from "contexts";
import { SignOutAltIcon, UserAltIcon } from '@patternfly/react-icons';
import { HeaderBox, SignInForm } from "components";
import { Link } from 'react-router-dom';
import { push } from "react-router-redirect";
import { decodeJwtToken } from "modules/security"
import { useQuery } from "react-query";
import { GetCurrentUser, GetUserQualities } from "api";
import { access } from "fs";
interface Props {
}

const TopBar: React.FC<Props> = () => {

    const {
        sess,
        setSess
    } = useContext(SessionsContext);
    const {
        navData,
        setNavData
    } = useContext(SideBarNavContext);
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [userData, setUserData] = useState<{ [key: string]: any }>({ undefined });

    const toggleSideBar = () => {
        setNavData({ active: !navData.active })
    }

    const accessToken = localStorage.getItem("x-imperso") || localStorage.getItem("x-access-token");
    const [userCompleteData, setUserCompleteData] = useState<{ [key: string]: any }>({ undefined });
    const [userQualities, setUserQualities] = useState<{ [key: string]: any }>({ undefined });
    const isImperso = localStorage.getItem("x-imperso");

    useEffect(() => {
        if (sess) {
            let decoded = decodeJwtToken(sess);

            setUserData(decoded)
            setIsConnected(true)
        } else setIsConnected(false)
    }, [sess])

    const logoutSession = () => {
        localStorage.removeItem("x-access-token")
        localStorage.removeItem("x-imperso")
        setSess(null);
        setIsConnected(false);
        push("/");
    }

    const deImpersonate = () => {
        localStorage.removeItem("x-imperso");
        window.location.reload();
    }

    const {
        isSuccess,
        isLoading
    } = useQuery({
        queryKey: ["SEARCH_CURRENT_USER", { access_token: accessToken }],
        queryFn: GetCurrentUser,
        onSuccess: async (response) => {
            setUserCompleteData(response)
        },
        onError: async (error: Error) => {

        }
    })
    const {
        data: additionalData,
        isLoading: isLoadingAdditional
    } = useQuery({
        queryKey: ["FETCH_USER_QUALITIES", {access_token: accessToken}],
        queryFn: GetUserQualities,
        enabled: !!userCompleteData,
        onSuccess: async (response) => {
            setUserQualities(response)
        },
        onError: async (error: Error) => {
        }
    });

    console.log("userQualities", userQualities)
    return (
        <React.Fragment>
            <header className={_css.container}>
                <Split hasGutter className={_css.mobile_version}>
                    <SplitItem className={_css.logo_box}>
                        <Bullseye>
                            <img
                                onClick={toggleSideBar}
                                src={HeaderMenuIcon} alt={"menu-icon"} style={{ height: 28 }} />
                        </Bullseye>
                    </SplitItem>
                    <SplitItem isFilled className={_css.top_mobile_logo}>
                        <img
                            src={"https://bap.s3.eu-de.cloud-object-storage.appdomain.cloud/logo_pn5dfx_c_scale,w_293.png"}
                            alt={"app-icon"} />
                    </SplitItem>
                </Split>
                <Grid className={_css.large_screens}>
                    <GridItem lg={isConnected ? 4 : 6}>
                        <picture>
                            <img
                                sizes="(max-width: 293px) 100vw, 293px"
                                srcSet="
                                    https://bap.s3.eu-de.cloud-object-storage.appdomain.cloud/logo_pn5dfx_c_scale,w_200.png 200w,
                                    https://bap.s3.eu-de.cloud-object-storage.appdomain.cloud/logo_pn5dfx_c_scale,w_293.png 293w"
                                src="https://bap.s3.eu-de.cloud-object-storage.appdomain.cloud/logo_pn5dfx_c_scale,w_293.png"
                                alt="bap-logo" />
                        </picture>
                    </GridItem>
                    {isConnected &&
                        <GridItem lg={8}>
                            <Grid hasGutter>
                                <GridItem lg={4}>
                                    <HeaderBox className={_css.box_one}>
                                        <div>
                                            <Title headingLevel="h1"><Truncate content={userData?.first_name} /></Title>
                                            <Text>{userData?.email}</Text>
                                        </div>
                                        {isImperso && (
                                            <div className={_css.icon} onClick={deImpersonate}>
                                                <UserAltIcon size="md" style={{ cursor: "pointer"}}/>
                                            </div>
                                        )}
                                    </HeaderBox>
                                </GridItem>
                                <GridItem lg={3}>
                                    <HeaderBox className={_css.box_two}>
                                        <img src={StatsTopBarButtonIcon} alt={"stats-icon-button"} />
                            {userQualities && userQualities.length > 0 && (
                                <div>{userQualities.map((quality: any) => (
                                    <Text key={quality.id}>{quality.name}</Text>
                                ))}</div>
                            )}
                                    </HeaderBox>
                                </GridItem>
                                <GridItem lg={3}>
                                    <HeaderBox className={_css.box_three}>
                                        <img
                                            src={"https://bap.s3.eu-de.cloud-object-storage.appdomain.cloud/2-layers.png"}
                                            alt={"stats-icon-button"}
                                        />
                                        <div>{(userCompleteData?.hours?.toFixed(1))}h</div>
                                    </HeaderBox>
                                </GridItem>
                                <GridItem lg={2} onClick={logoutSession}>
                                    <HeaderBox
                                        className={_css.box_four}
                                    >
                                        <Tooltip content={"Déconnectez-vous de la session en cours."}>
                                            <SignOutAltIcon />
                                        </Tooltip>
                                    </HeaderBox>
                                </GridItem>
                            </Grid>
                        </GridItem>}
                    {!isConnected &&
                        <GridItem lg={6} className={_css.large_screens}>
                            <Grid hasGutter>
                                <GridItem lg={2}>
                                    <Link to={"/register/user"}>
                                        <Tooltip content={"Créez votre compte personnel."}>
                                            <div
                                                className={_css.button_register_user}
                                                // onClick={logoutSession}
                                            >
                                                <UserAltIcon />
                                            </div>
                                        </Tooltip>
                                    </Link>
                                </GridItem>
                                <GridItem lg={10}>
                                    <SignInForm />
                                </GridItem>
                            </Grid>
                        </GridItem>}
                </Grid>
            </header>

        </React.Fragment>
    )
}

export { TopBar };
