import React, { Fragment, memo, useCallback, useEffect, useRef, useState } from "react";
// import { Field, Form as FinalForm, useForm } from "react-final-form";
import arrayMutators from "final-form-arrays";
import _css from "./TransferHoursForm.module.scss";
import {
    Alert,
    AlertActionCloseButton,
    Button,
    Chip,
    ChipGroup,
    Grid,
    GridItem,
    Skeleton,
    Spinner,
    Text,
    Form,
    Title,
    TabActionProps
} from "@patternfly/react-core";
import variants from "sass/colors.module.scss";
import { InputErrors } from "components";
import useRequestFetch from "react-requests-fetch";
import { FETCH_TRANSACTION_QUALITIES, TRANSFER_SESSION_HOURS } from "services/urls";
import { decodeJwtToken } from "modules/security";
import { useMutation, useQueryClient } from "react-query";
import { useForm, Field, Controller, SubmitHandler } from "react-hook-form";
import { TransferHours } from "api"
import { Service } from "./service";

type Message = {
    variant: "success" | "danger" | "default" | "warning" | "info" | undefined
    message: string
} | undefined

interface Props {
    toWhom: any
    fromWhom: any
    updateHours: any
    callback: any
}

type TransferHours = {
    hour: number,
    description: string,
    receiver_id: string,
    quality: string[]
}

const TransferHoursForm: React.FC<Props> = (
    {
        toWhom,
        fromWhom,
        updateHours,
        callback
    }
) => {
    const accessToken = localStorage.getItem("x-access-token");
    const [fetching, setFetching] = useState<boolean>(true);
    const [message, setMessage] = useState<Message>();
    const [chips, setChips] = useState<any>([]);
    const [blocked, isBlocked] = useState<boolean>(false);
    const formRef = useRef<any>();
    const required = value => (value ? undefined : value);
    const queryClient = useQueryClient()
    const { control, handleSubmit, setValue, getValues, formState: { isValid, } } = useForm<TransferHours>({
        mode: "onChange",
        defaultValues: {
            receiver_id: toWhom?.public_id,
            quality: []
        }
    })
    const { mutateAsync } = useMutation({
        mutationFn: TransferHours,
        mutationKey: ["TRANSFER_HOURS"],
        onSuccess: async () => {
            await queryClient.invalidateQueries(["SEARCH_CURRENT_USER"])
        }
    })
    const [reqUserQualities] = useRequestFetch({
        uri: FETCH_TRANSACTION_QUALITIES,
        headers: { "accepts": "application/json" }
    });

    const resetForm = (form) => {
        // @ts-ignore
        Array.from(form.elements).forEach((input) => input.value = '');
    }

    const submit: SubmitHandler<TransferHours> = async (values) => {
        await mutateAsync({
            "description": values.description,
            "receiver_id": values.receiver_id,
            "hours": values.hour,
            "quality": values.quality
        })
        // console.log(values)
        callback()
    }

    const deleteItem = (value) => {
        const updatedChips = chips.filter(chip => chip.id !== value.id);
        setChips(updatedChips);
        setValue("quality", updatedChips.map(chip => ({ id: chip.id, name: chip.name })));
    };


    useEffect(() => {
        (reqUserQualities && reqUserQualities.statusCode === 200) &&
            setChips(reqUserQualities.payload)
        setFetching(false)
    }, [reqUserQualities])

    // useEffect(() => {
    //     setValue("quality", chips.map(chip => chip.name));
    // }, [chips, setValue]);


    useEffect(() => {
        // Mise à jour des valeurs en gardant l'ID et le nom
        setValue("quality", chips.map(chip => ({ id: chip.id, name: chip.name })));
    }, [chips, setValue]);

    useEffect(() => {
        if (toWhom && accessToken) {
            if (String(toWhom.public_id) === String(decodeJwtToken(accessToken)["public_id"])) {
                setMessage({
                    variant: "danger",
                    message: "Vous ne pouvez pas transférer du temps à vous-même, sélectionnez un utilisateur dans le système qui n'est pas vous et continuez."
                })
                isBlocked(true)
            } else isBlocked(false)
        }
        // eslint-disable-next-line
    }, [toWhom])

    const serviceCallback = useCallback((value) => {
        const current = getValues("description")
        const result = `${value ? `@${value?.title}:`: ""} ${current}`;
        setValue("description", result)
    }, [])

    return (
        <Fragment>
            {(blocked && message) && <Alert isInline title={message.message} variant={message.variant} />}
            <Form
                onSubmit={handleSubmit(submit)}
            >
                <Grid hasGutter>
                    <GridItem>
                        <GridItem lg={10} sm={12}>
                            <Text>
                                Vous êtes sur le point de transférer des heures
                                à <b>{toWhom && toWhom["first_name"] + ' ' + toWhom["last_name"]}</b> si
                                vous êtes certain
                                de cette opération, veuillez indiquer le nombre d'heures que vous souhaitez
                                envoyer.
                                Notez que vous ne pouvez pas envoyer plus que le nombre d'heures dont vous
                                disposez dans votre compte.
                            </Text>
                        </GridItem>
                    </GridItem>

                    <GridItem lg={12}>
                        <Controller
                            name="hour"
                            rules={{ required: true }}

                            control={control}
                            render={({ field: { onChange, onBlur, name, value }, fieldState: { error } }) => (
                                <label htmlFor="hours">
                                    <span className={_css.labels}>Nombre d'heures</span>
                                    <input
                                        className={[
                                            _css.input,
                                            error && variants.error_input
                                        ].join(" ")}
                                        type="number"
                                        id="hours"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        name={name}
                                        placeholder={"Nombre d'heures"}
                                        value={value}
                                    />
                                    {error && <InputErrors message={error?.message as any} />}
                                </label>
                            )}

                        />
                    </GridItem>

                    <GridItem>
                        <span className={_css.labels}>Service</span>
                        <Service callback={serviceCallback} />
                    </GridItem>

                    <GridItem lg={12}>
                        <Controller
                            name="description"
                            rules={{ required: true }}

                            control={control}
                            render={({ field: { onChange, onBlur, name, value }, fieldState: { error } }) => (
                                <label htmlFor="description">
                                    <span className={_css.labels}>Brève description du service</span>
                                    <textarea
                                        className={[
                                            _css.input,
                                            error && variants.error_input
                                        ].join(" ")}
                                        id="description"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        name={name}
                                        style={{ minHeight: 250 }}
                                        placeholder={"Brève description du service"}
                                        value={value}
                                    />
                                    {error && <InputErrors message={error!.message as any} />}
                                </label>
                            )}

                        />
                    </GridItem>

                    <GridItem>
                        <Title headingLevel={"h4"}>Qualités</Title>
                        <Text>
                            Sélectionnez parmi les éléments de la liste ci-dessous que vous ne souhaitez
                            pas attribuer comme les qualités que vous avez vues chez l'utilisateur.
                            Ceux qui ont été laissés de côté seront choisis comme attributs de
                            l'utilisateur en tant que notes.
                        </Text><br />
                        {fetching && <Skeleton height="50px" screenreaderText="Loading contents" />}
                        {!fetching &&
                            <div className={_css.chipzone}>
                                <ChipGroup>
                                    {chips.map(currentChip => (
                                        <Chip key={currentChip.id} onClick={() => deleteItem(currentChip)}>
                                            {currentChip.name}
                                        </Chip>
                                    ))}
                                </ChipGroup>
                            </div>}
                    </GridItem>

                    {(!fetching && message) &&
                        <GridItem>
                            <Alert isInline variant={message.variant || "default"}
                                title={"Transfert d'heures"}
                                actionClose={<AlertActionCloseButton
                                    onClose={() => setMessage(undefined)} />}>
                                <Text>
                                    {message.message}
                                </Text>
                            </Alert>
                        </GridItem>}
                    <GridItem>
                        <Button isDisabled={fetching} type={"submit"}>
                            {fetching ? <Spinner size="sm" /> : <span>Transférer</span>}
                        </Button>
                    </GridItem>
                </Grid>
            </Form>
        </Fragment>
    )
}

export default memo(TransferHoursForm);
